import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ServicesList } from '../components';

import styles from './services.module.scss';

const BlindsPage = () => {
  const { contentfulBlindsPage } = useStaticQuery(graphql`
    {
      contentfulBlindsPage {
        title
        subtitle
        blindsList {
          name
          description
          price
          effects {
            description
            isChecked
          }
        }
      }
    }
  `);

  return (
    <main>
      {/* HERO */}
      <div className={`${styles.hero} ${styles.carpet}`}>
        <div className="container">
          <h1 className="heading">{contentfulBlindsPage.title}</h1>
          <h2 className="subheading">{contentfulBlindsPage.subtitle}</h2>
        </div>
      </div>
      {/* Content */}
      <div className={styles.content}>
        <div className="container">
          <ServicesList data={contentfulBlindsPage.blindsList} />
        </div>
      </div>
    </main>
  );
};

export default BlindsPage;
